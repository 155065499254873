
.container .main-departments {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.container .main-departments div {
  width: 40%;
  height: fit-content;
  margin: 20px auto;
  border-radius: 20px;
  background-color: var(--white-color);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  box-shadow: 0 0 7px #a3b9d291;
}

.container .main-departments div img {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-bottom-right-radius: 100%;
  transition: 1s ease-in-out;
  -webkit-transition: 1s ease-in-out;
  -moz-transition: 1s ease-in-out;
  -ms-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
  object-fit: cover;
}
.container .main-departments div img:hover {
  border-bottom-right-radius: 10px;
}
.container .main-departments div h1 {
  margin-right: 10px;
  margin-left : 10px;
  word-spacing: 3px;
}
.container .main-departments div a {
  width: 90%;
  border: none;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  margin: 20px auto;
  padding: 5px;
  color: var(--white-color);
  font-size: 18px;
  background-color: var(--main-color);
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.container .main-departments div a:hover {
  background-color: var(--second-color);
}


@media (max-width: 1100px) {
  .container .main-departments div {
    width: 90%;
  }
  .container .main-departments div img {
    height: 400px;
  }
  .container .main-departments {
    flex-direction: column;
  }
}
@media(min-width:1570px){
  .container .main-departments div {
    width: 23%;
  }
}
