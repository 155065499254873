.contact-us {
  padding-top: 80px;
}
.contact-us .container {
  display: flex;
  justify-content: center;
}

.contact-us .container .contact-form {
  width: 800px;
  padding: 40px 20px;
  background-color: var(--white-color);
  font-size: 20px;
}
.contact-us .container .contact-form form .form-group label {
  display: block;
  margin-bottom: 10px;
  color: rgb(51, 47, 47);
}
.contact-us .container .contact-form form .form-group input,
.contact-us textarea {
  width: 100%;
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  background-color: var(--bg-color);
  margin-bottom: 10px;
}
.contact-us textarea {
  height: 20vh;
  resize: none;
  color: #807777;
}
.contact-us .container .contact-form button {
  width: 100%;
  border: none;
  padding: 8px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  background-color: var(--main-color);
  color: var(--white-color);
  font-size: 18px;
  cursor: pointer;
}
.contact-us .container .contact-details {
  width: 350px;
  background-color: var(--third-color);
  padding: 20px;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}
.contact-us .container .contact-details h1 {
  color: var(--bg-color);
  margin-bottom: 20px;
}
.contact-us .container .contact-details ul li {
  display: flex;
  align-items: center;
  margin: 30px auto 60px;
  position: relative;
}
.contact-us .container .contact-details ul li::before {
  content: "";
  position: absolute;
  top: 70px;
  right: 0;
  width: 100%;
  height: 1px;
  background-color: var(--bg-color);
}
.contact-us .container .contact-details ul li a {
  margin-right: 10px;
  margin-left: 10px;
  color: var(--bg-color);
  font-family: "Cairo", sans-serif;
  font-weight: 700;
}
.contact-us .container .contact-details ul li svg {
  width: 30px;
  height: 30px;
  border: 2px solid var(--bg-color);
  border-radius: 8px;
  padding: 3px;
  fill: var(--bg-color);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
@media (max-width: 1100px) {

  .contact-us .container .contact-details ul li::before {
    top: 50px;
  }

}
@media(max-width:1100px){
  .contact-us .container .contact-details,
  .contact-us .container .contact-form {
    width: 100%;
  }
}
@media(max-width:860px){
    .contact-us .container{
        flex-direction: column;
    }
}