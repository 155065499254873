.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top:70px;
}
.login-form {
  background: var(--white-color);
  padding: 2rem;
  border-radius: 18px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 400px;
}
.input-group {
  margin-bottom: 1rem;
}
.input-group label {
  display: block;
  margin-bottom: 0.5rem;
}
.input-group input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 12px;
  outline: none;
}
.login-container button {
  width: 100%;
  padding: 0.5rem;
  border: none;
  background-color: var(--main-color);
  color: white;
  border-radius: 12px;
  cursor: pointer;
  transition: var(--main-tran);
}
.login-container button:hover {
  background-color: #0056b3;
}
