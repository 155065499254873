.ServicesSection {
  padding-top: 30px;
}
.services .container {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-bottom: 20px;
}
.service-box {
  position: relative;
  padding: 35px 20px;
  border-radius: 20px;
  box-shadow: 0 0 7px rgb(163 185 210 / 20%);
  background-color: var(--white-color);
}
.service-box .service-number span {
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%);
  padding: 8px 21px;
  font-size: 20px;
  border-radius: 14px;
  transition: var(--main-tran);
  color: var(--white-color);
  background-color: var(--third-color);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}
.service-box .service-number .service-span-en {
  right: inherit;
  left: -22px;
}
.service-box .service-description {
  font-size: 18px;
  margin-right: 30px;
  margin-left: 30px;
  transition: var(--main-tran);
  -webkit-transition: var(--main-tran);
  -moz-transition: var(--main-tran);
  -ms-transition: var(--main-tran);
  -o-transition: var(--main-tran);
}
.service-box .service-description
.service-box:hover .service-description {
  margin-right: 38px;
}
.service-box .service-number span:hover {
  border-radius: 50%;
}
