@font-face {
  font-family: aa-aniq;
  src: url(../public/font/ArbFONTS-AA-ANIQ.otf);
}
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: aa-aniq;
}
:root {
  --main-color: #02245b;
  --second-color: #2c4976;
  --third-color: #526a8e;
  --text-color: #1e1f22;
  --footer-color: #01122e;
  --red-color: #e74b4b;
  --white-color: #fff;
  --black-color: #000;
  --bg-color: #f4f6fa;
  --main-tran: 0.3s;
}
::selection {
  color: var(--white-color);
  background-color: var(--main-color);
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: var(--bg-color);
}
::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
}
a {
  text-decoration: none;
}
li {
  list-style: none;
}
html {
  scroll-behavior: smooth;
}
body {
  background-color: var(--bg-color);
}
body,html{
  overflow-x: hidden;
}
:dir(ltr){
  font-family: "Cairo", sans-serif;
  font-weight: 700;
}
.container {
  width: 90vw;
  margin: auto;

}
.loader {
  display: flex;
  justify-content : center;
  column-gap: 10px;
}

.dot {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #008ae6;
  box-shadow: 0 5px 10px rgba(255, 255, 255, 0.01);
  animation: animate 1s ease-in-out infinite alternate;
}

.dot:nth-child(1){
  animation-delay: -0.25s;
}

.dot:nth-child(2){
  background: #e60000;
  animation-delay: -0.5s;
}

.dot:nth-child(3){
  background: #ffcc00;
  animation-delay: -0.75s;
}

.dot:nth-child(4){
  background: #008800;
  animation-delay: -1s;
}

@keyframes animate {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(5px);
  }
}
