.clients-section {
  padding-bottom: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;

  }
.clients-section .container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  gap: 20px;
}
.clients-section .container .client {
  background-color: var(--white-color);
  padding: 20px;
  border-radius: 20px;
  position: relative;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  margin: 10px 0;
}
.clients-section .container .client h1 {
  color: var(--main-color);
}
.clients-section .container .client p {
  color: var(--third-color);
  font-family: "Cairo", sans-serif;
  font-weight: 400;
}
.clients-section .container .client img {
  width: 100%;
  height: 300px;
  border-bottom-right-radius: 100px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 10px;
  margin-top: 5px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}
.clients-section .container .client img:hover {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
.clients-section .container .client img:hover {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.clients-section .container .client::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  background-color: var(--main-color);
  width: 50%;
  height: 10px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
@media (max-width: 400px) {
    .clients-section .container {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
