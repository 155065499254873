.about {
  padding-top: 100px;
  font-weight: 200 !important;
}
.about .container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
.about .container .about-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.about .container .about-text h1 {
  font-size: 35px;
  padding-right: 10px;
  border-right: 4px solid var(--main-color);
}
.about .container .about-text h1 span {
  color: var(--main-color);
}
.about .container .about-text .about-description {
  width: 70%;
  font-size: 20px;
  font-weight: 600;
  color: var(--text-color);
}
.about .container .about-text .experience {
  font-weight: bold;
  color: var(--second-color);
}
.about .container .about-text .experience span:nth-child(1) {
  position: relative;
  font-size: 80px;
}
.about .container .about-text .experience span:nth-child(1)::before {
  content: "+";
  position: absolute;
  top: -5px;
  left: -35px;
  font-size: 45px;
}
.about .container .about-text .experience span:nth-child(2) {
  font-size: 22px;
  margin-right: 5px;
}
.about .container .gallery {
  display: flex;
  gap: 30px;
}
.about .container .gallery .gallery-img {
  height: 500px;
  width: 170px;
  overflow: hidden;
  border-radius: 20px;
}
.about .container .gallery .gallery-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: var(--main-tran);
}
.about .container .gallery .gallery-img:hover img {
  transform: scale(1.1);
}
.about .container .gallery .img-one {
  margin-top: 100px;
}
.about .container .gallery .img-two {
  margin-top: 60px;
}
.success .container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.success .container .success-box {
  position: relative;
  padding: 32px 20px;
  border-radius: 14px;
  background-color: var(--white-color);
  box-shadow: 0 0 7px rgb(163 185 210 / 20%);
}
.success .container .success-box svg {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  font-size: 60px;
  border-radius: 16px;
  top: 50%;
  transform: translateY(-50%);
  right: -17px;
  transition: var(--main-tran);
  color: var(--white-color);
  background-color: var(--main-color);
}

.success .container .success-box svg:hover {
  border-radius: 50%;
}
.success .container .success-box p {
  font-size: 22px;
  font-weight: bold;
  margin-right: 35px;
  margin-left: 40px;
  color: var(--main-color);
  transition: var(--main-tran);
}
.success .container .success-box:hover p {
  margin-right: 40px;
}
.success .container .sucess-item-IconEn {
  right: inherit !important;
  left: -17px;
}
@media (max-width: 1160px) {
  .about .container {
    flex-direction: column;
  }
  .about .container .about-text {
    width: 100%;
    text-align: center;
  }
  .about .container .about-text .about-description {
    width: 100%;
  }
  .about .container .gallery {
    width: 100%;
    justify-content: center;
    padding-top: 20px;
  }
}
