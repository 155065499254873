
.swiper {
  width: 100%;
  height: 100%;
  margin-bottom: 100px;
  background-color: var(--bg-color);
  direction: rtl !important;
}


.swiper-slide {
  text-align: center;
  font-size: 18px;
  width: 600px;
  height: 600px;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;

}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--third-color);
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: var(--third-color);
  fill: none;
  stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}
.swiper-button-next, .swiper-rtl .swiper-button-prev{
    color: var(--third-color);
}