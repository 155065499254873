
.licenses-section .container .license-lists {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  gap: 20px;
}
.licenses-section .container .license-box {
  height: 350px;
  padding: 15px;
  background-color: var(--white-color);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  margin: 0 auto;
}
.licenses-section .container .license-box img {
  width: 350px;
  max-width: 100%;
  height: 265px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  object-fit: cover;
  border: 1px solid #d5dae3;
}
.licenses-section .container .license-box img:hover{
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}
.pagination ul {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  direction: ltr;
}
.pagination ul li {
  border: 1px solid var(--main-color);
  width: 40px;
  margin: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pagination ul li.active {
  background-color: var(--main-color);
  color: white;
}
@media (max-width: 500px) {
  .licenses-section .container .license-lists {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 40px;
  }
}
.btns {
  display: flex;
  gap: 7px;
  margin-top: 5px;
}
.btns a {
  width: 50%;
  padding: 5px;
  text-align: center;
  border-radius: 14px;
  transition: var(--main-tran);
  color: var(--white-color);
  background-color: var(--main-color);
}
.btns a:nth-child(2) {
  background-color: var(--third-color);
}
.btns a:hover {
  filter: brightness(90%);
}
