.home {
  padding-top: 20px;
  margin-bottom: 300px;
  position: relative;
}
.home .container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home .container .text {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  text-align: right;
}
.home .container .text-ar {
  text-align: right;
}
.home .container .text-en {
  text-align: left;
}
.home .container .swiper-en .swiper-slide{
  direction: ltr !important;
}
.home .container .swiper-ar .swiper-slide{
  direction: rtl !important;
}
.home .container .swiper .swiper-button-next,
.home .container .swiper-rtl .swiper-button-prev {
  display: none;

}

.home .container .text h1 {
  font-size: 36px;
  color: var(--main-color);
}
.home .container .text h2 {
  font-size: 32px;
  color: var(--main-color);
}
.home .container .text p {
  width: 70%;
  font-size: 20px;
  font-weight: 500;
  color: var(--text-color);
}
.home .container .show-more a {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  font-size: 20px;
  color: var(--black-color);
}
.home .container .show-more a svg{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  fill: var(--white-color);
  background-color: var(--second-color);
  transition: var(--main-tran);
}
.home .container .show-more a:hover svg {
  border-radius: 50%;
}
.home .container .gallery {
  display: flex;
  gap: 30px;
  justify-content: space-around;
  align-items: flex-end;
}

.home .container .gallery .gallery-img {
  height: 500px;
  width: 170px;
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  animation: moveUpDown 3s ease-in-out 1.5s infinite;
}
.home .container .gallery .gallery-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: var(--main-tran);
}
.home .container .gallery .gallery-img:hover img {
  transform: scale(1.1);
}
.home .container .gallery-two {
display: flex;
justify-content: center;
width: 80%;
flex-wrap: wrap;
}

.home .container .gallery-three {
  width: 600px;
  transform: rotate(7deg);
  -webkit-transform: rotate(7deg);
  -moz-transform: rotate(7deg);
  -ms-transform: rotate(7deg);
  -o-transform: rotate(7deg);
  -webkit-clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
  clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
}
.home .container .gallery-three:hover {
  clip-path: polygon(
    50% 0%,
    83% 12%,
    100% 43%,
    94% 78%,
    68% 100%,
    32% 100%,
    6% 78%,
    0% 43%,
    17% 12%
  );
}
.home .container .gallery-two .gallery-img {
width: 35%;
  height: 200px;
}

.home .container .gallery .img-one {
  animation-delay: 0s;
  margin-top: 100px;
}
.home .container .gallery .img-two {
  animation-delay: 0.5s;
  margin-top: 60px;
}
.home .container .gallery .img-three {
  animation-delay: 1s;
  margin-top: 20px;
}
.home .container .gallery .img-four {
  animation-delay: 0.2;
  margin-top: 10px;
}
.show-more-button {
  display: block;
  text-align: center;
  color: var(--white-color);
  background-color: #5e89cc;
  width: 200px;
  padding: 5px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin: 30px auto;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.show-more-button:hover {
  background-color: var(--main-color);
}
.go-down {
  color: var(--main-color);
  position: absolute;
  top: 120%;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}
.go-down:hover {
  color: var(--main-color-alt);
}
.go-down svg {
  animation: bouncing 1.5s infinite;
}
.go-down svg path {
  fill: var(--main-color);
}

@keyframes bouncing {
  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40%,
  60% {
    transform: translateY(-15px);
  }
}
@keyframes moveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
    -webkit-transform: translateY(-30px);
    -moz-transform: translateY(-30px);
    -ms-transform: translateY(-30px);
    -o-transform: translateY(-30px);
  }
}

@media (max-width: 1160px) {
  .home .container .text {
    width: 100%;
    text-align: center;
  }
  .home .container .swiper .swiper-slide {
    display: block;
    height: auto;
  }
  .home .container .text p {
    width: 100%;
  }
  .home .container .show-more a {
    justify-content: center;
    margin-bottom: 50px;
  }
  .home .container .gallery {
    width: 100%;
    justify-content: center;
    padding-top: 20px;
  }
}
