.preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999999999;
  transition: var(--main-tran);
  top: 0px;
  background-color: #101010;
}
